import React from "react";
import { useSnackbar } from "notistack";
import { Grid } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as StripeService from "../../services/StripeService";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";

interface IProps {
    onLoadingChange: (isLoading: boolean) => void;
    confirmPostBack?: () => void;
}
export const ConfirmFreePaymentForm: React.FC<IProps> = ({ onLoadingChange, confirmPostBack }) => {
    const { enqueueSnackbar } = useSnackbar();
    let context = React.useContext(AppContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        onLoadingChange(isLoading);
    }, [isLoading, onLoadingChange]);

    const handleSubmit = async () => {
        setIsLoading(true);

        StripeService.ConfirmFreeSubscription()
            .then(success => {
                enqueueSnackbar("Your free subscription has been confirmed.", {
                    variant: "success",
                });

                if (confirmPostBack) {
                    confirmPostBack();
                } else {
                    setTimeout(() => window.location.href = '/', 1000);
                }
            })
            .catch((error: Response) => {
                enqueueSnackbar("We have been unable to process this request. Please close your browser and try again", {
                    variant: "error",
                });
                setIsLoading(false);
            });
    }

    return (
        <>
            {!isLoading &&
                <Grid container>
                    <Grid item xs={12}>
                        <a style={
                            {
                                fontSize: "16px", margin: "30px 0 15px 0", cursor: "pointer", display: "block", position: "relative", textDecoration: "underline", textAlign: "center"
                            }}
                            onClick={() => { handleSubmit() }}>
                            Confirm Free Subscription
                        </a>
                    </Grid>
                </Grid>
            }

            {isLoading &&
                <Grid item xs={12}>
                    <LoadingComponent label="Please Wait"></LoadingComponent>
                </Grid>
            }
        </>
    )
};

