import moment from "moment";
import React from "react";
import * as Styles from "./styles/MyAccountContainerStyles";
import { Grid } from "@material-ui/core";
import { IBidder } from "../../interfaces/bidders/IBidder";

export const SubscriptionExpiry: React.FC<{ bidder: IBidder }> = ({
  bidder
}) => {
  const classes = Styles.MyAccountContainerStyles();

  const now = moment();
  const endDate = moment(bidder.subscriptionEndDate);
  const freeEndDate = moment(bidder.freeSubscriptionEndDate);

  // Get the current in date end date..
  let currentEndDate: moment.Moment | null = endDate.isValid() && endDate >= now ? endDate : freeEndDate.isValid() && freeEndDate >= now ? freeEndDate : null;

  // If expired (null date) just get the most recent expiry date (This is so Subscription expired have an expiry date shown)
  // Check at least 1 of the dates is valid, so that bidders with no subscription history dont show the default time (moment(0))
  if (currentEndDate === null && (endDate.isValid() || freeEndDate.isValid())) {
    currentEndDate = moment.max(endDate.isValid() ? endDate : moment(0), freeEndDate.isValid() ? freeEndDate : moment(0));
  }

  return moment(currentEndDate).isValid() ? (
    <Grid container>
      <Grid item xs={6}>
        <h4 className={classes.orangeText}>ACCOUNT {currentEndDate! <= now ? 'EXPIRED' : 'EXPIRY'}</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.greyText}>{currentEndDate?.format("DD/MM/YYYY")}</h4>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
