import moment from "moment";
import { AccountType, BidderStatus } from "../../../interfaces/bidders/IBidder";

// export const GetSubscriptionStatus = (endDate: string, freeEndDate: string, accountType: AccountType) => {
//   if (accountType === AccountType.Member) {
//     return "Member Account";
//   }

//   if (accountType === AccountType.Free) {
//     return "Free Account";
//   }

//   const now = moment();
//   const end = moment(endDate);
//   const freeEnd = moment(freeEndDate);

//   if (moment(end).isValid()) {
//     if (now > end) {
//       return "Subscription Expired";
//     } else {
//       return "Subscribed";
//     }
//   } else if(moment(freeEnd).isValid()) {
//     if (now > end) {
//       return "Free Subscription Expired";
//     } else {
//       return "Free Subscription Plan";
//     }
//   } else {
//     return "No Subscription";
//   }
// };

export const GetSubscriptionStatus = (bidderStatus: BidderStatus, accountType: AccountType) => {
  if (accountType === AccountType.Member) return "Member Account";
  switch (bidderStatus) {
    case BidderStatus.AwaitingValidation:
      return "Awaiting ID Verification";
    case BidderStatus.PotentialDuplicate:
      return "Awaiting ID Verification";
    case BidderStatus.Suspended:
      return "Suspended";
    case BidderStatus.SubscriptionExpired:
      return "Subscription Expired";
    case BidderStatus.ActiveFull:
      return "Subscribed";
    case BidderStatus.ActiveFree:
      return "Free Subscription Plan";
  }
}