import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";
import * as Styles from "./styles/ContinueRegistrationStyles";
import { RegistrationSteps } from "../bidder-registration/steps/RegistrationSteps";
import { RegistrationStep } from "../../services/BidderService";
import { AppContext } from "../../contexts/AppContext";
import { BidderStatus } from "../../interfaces/bidders/IBidder";
import { IBidderState } from "../../interfaces/bidders/IBidderState";

const ContinueRegistration: React.FC = () => {
  const classes = Styles.ContinueRegistrationStyles();
  const context = React.useContext(AppContext);
  const history = useHistory();
  const registrationStep = RegistrationStep(context.bidderState?.bidder);

  const renewal = (bidderState: IBidderState) =>
    bidderState.bidderLoaded &&
    (bidderState.bidder.bidderStatus === BidderStatus.SubscriptionExpired ||
      bidderState.bidder.bidderStatus === BidderStatus.ActiveFree ||
      bidderState.bidder.bidderStatus === BidderStatus.ActiveFull ||
      bidderState.bidder.bidderStatus === BidderStatus.Suspended);

  const [message, setMessage] = React.useState<string>();
  const [isRenewal, setIsRenewal] = React.useState<boolean | string>(renewal(context.bidderState));
  const [showFreeMsg, setShowFreeMsg] = React.useState<boolean>(false);
  const [showPaymentMsg, setShowPaymentMsg] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsRenewal(renewal(context.bidderState));

    if (history.location.pathname !== "/Register" &&
      history.location.pathname !== "/Renewal" &&
      history.location.pathname !== "/RenewalProcess" &&
      context.bidderState.bidder.bidderStatus === BidderStatus.SubscriptionExpired) {
      setShowPaymentMsg(true);
    }

    if (history.location.pathname !== "/Register" &&
      history.location.pathname !== "/Renewal" &&
      history.location.pathname !== "/RenewalProcess" &&
      (context.bidderState.bidder.bidderStatus === BidderStatus.ActiveFree)) {
      setShowFreeMsg(true);
    }

  }, [context.bidderState]);

  React.useEffect(() => {
    const showMessage =
      context.bidderState.bidderLoaded &&
      history.location.pathname !== "/Register" &&
      history.location.pathname !== "/Renewal" &&
      registrationStep !== RegistrationSteps.Unknown &&
      (registrationStep !== RegistrationSteps.Complete || context.bidderState.bidder.bidderStatus === BidderStatus.PotentialDuplicate);

    if (!showMessage) {
      setMessage(undefined);
      return;
    }

    if (history.location.pathname !== "/Register" &&
      context.bidderState.bidder.bidderStatus === BidderStatus.AwaitingPayment) {
        history.push("/Register");
        return;
    }

    switch (registrationStep) {

      // REMOVED FOR COMMERCIAL CHANGES IN CR28
      // case RegistrationSteps.AccountType:
      //   setMessage("You must select an account before you can bid");
      //   break;
      // case RegistrationSteps.Payment:
      //   setMessage("You must pay for a subscription before you can bid");
      //   break;
      case RegistrationSteps.AwaitingVerification:
        setMessage("We are currently verifying your Id. Until this is complete you will be unable to bid");
        break;
      case RegistrationSteps.FailedValidation:
        setMessage("Some of your documents have not passed our validation. Please click here to update the documents you have provided");
        break;
      case RegistrationSteps.IdValidation:
        setMessage("We require some documents from you before you can bid. Please click here to provide them");
        break;
      default:
        if (context.bidderState.bidder.bidderStatus === BidderStatus.PotentialDuplicate) {
          setMessage("We are currently verifying your account. Until this is complete you will be unable to bid");
        } else {
          setMessage(undefined);
        }
        break;
    }
  }, [context, registrationStep, history.location]);

  return (
    <div>
      {showFreeMsg &&

        <Box
          bgcolor="error.main"
          color="error.contrastText"
          className={classes.warningMessageContainer}
          p={2}
          onClick={() => {
            history.push('/Renewal');
          }}
        >
          <Typography className={classes.textLayout}>
            <InfoIcon className={classes.iconPosition} />
            You are currently on our FREE Subscription package.&nbsp;
            <Typography
              component="span"
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Please upgrade
            </Typography>
            &nbsp;to access and bid on all listings.
          </Typography>

        </Box>
      }

      {showPaymentMsg &&

        <Box
          bgcolor="error.main"
          color="error.contrastText"
          className={classes.dangerMessageContainer}
          p={2}
          onClick={() => {
            history.push('/Renewal');
          }}
        >
          <Typography className={classes.textLayout}>
            <InfoIcon className={classes.iconPosition} />
            You don't have an active subscription, please subscribe to continue using our service. Click here.
          </Typography>

        </Box>
      }

      <Box
        bgcolor="info.main"
        color="info.contrastText"
        className={message ? classes.messageContainer : classes.hidden}
        p={2}
        onClick={() => {
          if (isRenewal) {
            history.push("/Renewal");
          } else {
            history.push("/Register");
          }
        }}
      >
        <Typography className={classes.textLayout}>
          <InfoIcon className={classes.iconPosition} />
          {message}
        </Typography>
      </Box>
    </div>
  );
};

export default ContinueRegistration;
