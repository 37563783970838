import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Auction } from "../../../services/AuctionService";
import { AppContext } from "../../../contexts/AppContext";
import { useLotMedia, Media } from "../../../services/MediaService";
import { useMediaQuery, Theme, Box, Typography, Grid, CircularProgress, Chip, Link } from "@material-ui/core";
import { RemoveFromWatchlistIcon, AddToWatchlistIcon } from "../../icons/HomePageIcons";
import { ellipsisText } from "../../../helpers/text-format/TextFormat";
import TimerContainer from "../../bid/sub-components/TimerContainer";
import * as Styles from "../styles/WatchlistItemStyles";
import BidGridComponent from "../../bid/BidGridComponent";
import { IMsalContext } from "../../../authentication/MsalContext";
import { useMsal } from "../../../authentication/MsalProvider";
import { useSignalrLatestBid } from "../../view-lot/hooks/useSignalR";
import { useSnackbar } from "notistack";

interface WatchlistItemProps {
  auction: Auction;
  watchlistHandler: (auctionId: string) => Promise<void>;
  index: number;
}

export const WatchlistItem: React.FC<WatchlistItemProps> = ({ auction, watchlistHandler, index }) => {
  const msal: IMsalContext = useMsal();

  const classes = Styles.WatchlistItemStyles();
  const context = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isWatching, setIsWatching] = useState(context.bidderState.checkWatchlist({ auctionId: auction.id }));
  const [updatingWatchlist, setUpdatingWatchlist] = useState(false);
  const [mediaUrl, setMediaUrl] = React.useState<string>();
  const [media] = useLotMedia(auction.lotId, auction.lotMediaCacheName);
  const [signalrLatestBid] = useSignalrLatestBid(auction.id);
  const [status, setStatus] = React.useState<number>(auction.status);
  const [closeDate, setCloseDate] = React.useState<string>(auction.auctionEnd);

  useEffect(() => {
    setUpdatingWatchlist(false);
  }, [isWatching, context.bidderState]);
  useEffect(() => {
    if (status !== auction.status) {
      auction.status = status;
    }
  }, [status, auction])
  useEffect(() => {
    if (closeDate !== auction.auctionEnd) {
      auction.auctionEnd = closeDate;
    }
  }, [closeDate, auction])
  useEffect(() => {
    setIsWatching(context.bidderState.checkWatchlist({ auctionId: auction.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auction.id]);
  useEffect(() => {
    if (signalrLatestBid) {
      setStatus(signalrLatestBid?.auctionInfo.auctionStatusCode);
      setCloseDate(signalrLatestBid?.auctionInfo.endDateTimeUtc);
    }
  }, [signalrLatestBid, auction])
  useEffect(() => {
    if (media !== null && media.length > 0) {
      const primaryMedia: Media[] = media.filter(item => {
        return item.order === 0;
      });

      if (primaryMedia.length > 0) {
        setMediaUrl(primaryMedia[0].versions.thumbnail);
      } else if (primaryMedia.length > 1) {
        setMediaUrl(primaryMedia[0].versions.thumbnail);
      } else {
        setMediaUrl(media[0].versions.thumbnail);
      }
    }
  }, [auction.id, media]);

  const handleWatchlistClickEvent = (e: any) => {
    e.stopPropagation();
    setUpdatingWatchlist(true);
    watchlistHandler(auction.id).catch(() => {
      enqueueSnackbar("An error occured with your watchlist, please try again", { variant: "error" });
      setUpdatingWatchlist(false);
    });
  };

  const getWatchingContainerClasses = () => {
    return `${classes.watchingContainer} ${isWatching ? classes.watching : classes.notWatching}`;
  };

  const getItemContainerClasses = () => {
    return `${classes.itemContainer} 
      ${isWatching ? classes.watchingContainerBorder : classes.notWatchingContainerBorder} 
      ${index % 2 ? classes.altBackground : null}`;
  };

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const getTitleLength = () => {
    if (xs) {
      return 75;
    }

    if (sm) {
      return 75;
    }

    if (md) {
      return 55;
    }

    if (lg) {
      return 95;
    }

    return 95;
  };

  const getAuctionImageElement = () => {
    if (mediaUrl !== undefined) {
      return (
        <Grid component={RouterLink} to={`/Lot/${auction.id}`}>
          <Box
            component="div"
            style={{
              height: sm ? "250px" : "100%",
              width: "auto",
              marginTop: msal.accounts.length > 0 ? "-60px" : "0px",
              backgroundImage: `url(${mediaUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></Box>
        </Grid>
      );
    }
    return <></>;
  };

  const getGoogleMapsLink = () => {
    return (
      <Typography
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        component={Link}
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(auction.metadata.postcode)}`}
        target="_blank"
        variant="button"
        color="textPrimary"
        gutterBottom
      >
        {auction.metadata.location}
      </Typography>
    );
  };

  const updatingWatchlistComponent = () => {
    return (
      <Grid item xs={12} className={classes.updatingWatchlist}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <CircularProgress color="primary" size={60} thickness={8} />
          </Grid>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Updating Watchlist...
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {isWatching && (
        <Grid container alignItems="stretch" className={getItemContainerClasses()}>
          {updatingWatchlist ? (
            updatingWatchlistComponent()
          ) : (
            <>
              <Grid item md={3} xs={12} className={classes.imageContainer}>
                {msal.accounts.length > 0 && (
                  <Box
                    className={getWatchingContainerClasses()}
                    onClick={(e: any) => {
                      handleWatchlistClickEvent(e);
                    }}
                  >
                    {isWatching ? <RemoveFromWatchlistIcon /> : <AddToWatchlistIcon />}
                  </Box>
                )}

                {getAuctionImageElement()}
              </Grid>
              <Grid item md={9} xs={12} className={classes.mainDetailsContainer}>
                <Grid container direction="column" justify="center" alignItems="stretch">
                  <Grid item container spacing={2} alignItems="stretch">
                    <Grid component={RouterLink} to={`/Lot/${auction.id}`} item md={6} sm={12} xs={12}
                      className={[classes.lotDetailsContainer, classes.noLinkDecoration].join(' ')}>
                      <Grid container alignItems="center">
                        <Grid item className={classes.auctionTagItem}>
                          <Chip
                            size="small"
                            color="primary"
                            label={`CATEGORY ${auction.metadata.damageCategory ? auction.metadata.damageCategory : "Unknown"}`}
                            className={classes.category}
                          />
                        </Grid>
                        <Grid item className={classes.auctionTagItem}>
                          {auction?.metadata.isFreeSubscription && (
                            <Chip
                              size="small"
                              color="secondary"
                              label="FREE BIDDER LISTING"
                              className={classes.category}
                              style={{ maxWidth: "185px", display: "flex" }}
                            />
                          )}
                        </Grid>
                        <Grid item className={classes.auctionTagItem}>
                          <Box className={classes.memberImageContainer}>
                            <img alt="logo" src={auction.memberImageUrl} height="20px" width="auto"></img>
                          </Box>
                        </Grid>
                        <Grid item className={classes.auctionTagItem}>
                          <Box>{getGoogleMapsLink()}</Box>
                        </Grid>
                      </Grid>
                      <Box>
                        <Typography
                          component={RouterLink}
                          to={`/Lot/${auction.id}`}
                          variant="h6"
                          style={{ color: "inherit", textDecoration: "inherit" }}
                        >
                          {ellipsisText(auction.metadata.title, getTitleLength())}
                        </Typography>
                      </Box>
                      <Grid container spacing={0} alignItems="stretch" className={classes.lotDetailsAmountContainer}>
                        <Grid item>
                          <Box>
                            <TimerContainer
                              endDateInput={closeDate}
                              auctionStatus={status}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} className={classes.metricsColumn}>
                      <BidGridComponent auction={auction} isWatchlistMode={true} auctionUpdated={(auction) => {
                        setStatus(auction.auctionStatusCode);
                        setCloseDate(auction.endDateTimeUtc);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};
