import React from "react";
import { useSnackbar } from "notistack";
import { TableCell, Button, Hidden, CircularProgress, ButtonGroup, Chip } from "@material-ui/core";
import { Link } from "react-router-dom";

import { DateToDateString } from "../../helpers/dateHelper";
import { AuctionListing, AuctionStatus } from "../../services/AuctionService";
import { AppContext } from "../../contexts/AppContext";
import { useBidWithPostAndSignalR } from "../../services/BidService";
import { currencyFormat } from "../../helpers/text-format/TextFormat";
import { isBidderWinning } from "../../interfaces/auctions/ILatestBid";

interface WinHistoryRowProps {
  auctionListing: AuctionListing;
}

export const WinHistoryRow: React.FC<WinHistoryRowProps> = ({ auctionListing }) => {
  const context = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [latestBid, isFetching] = useBidWithPostAndSignalR(context, auctionListing.id, (code, message) => {
    enqueueSnackbar(message, { variant: "error" });
  }, () => { });

  const message = () => {
    const isWinning = isBidderWinning(latestBid, context.bidderState.bidder.id);

    switch (auctionListing.status) {
      case AuctionStatus.Active: {
        return isWinning ? "You are the highest bidder" : "You have been outbid";
      }
      case AuctionStatus.ActiveButSuspended: {
        return "Auction suspended";
      }
      case AuctionStatus.AuctionEndedAndWon: {
        return isWinning ? "Auction won" : "Auction lost";
      }
      case AuctionStatus.AuctionEndedButNotWon: {
        return "Auction reserve not met";
      }
      case AuctionStatus.AuctionEndedButSuspended: {
        return "Auction suspended";
      }
      case AuctionStatus.AuctionCollectedRecently:
      case AuctionStatus.CompletedAsLotCollected: {
        return isWinning ? "Collected" : "Auction lost";
      }
      case AuctionStatus.CompletedAsArchived: {
        return "Auction deleted/archived";
      }
      default: {
        return "Auction lost";
      }
    }
  };

  const auctionStatus = () => {
    return <div style={{ display: "flex", alignItems: "center" }}>{message()}</div>;
  };

  return (
    <>
      {isFetching && latestBid === null && (
        <>
          <Hidden smDown>
            <TableCell colSpan={6} align="center">
              <CircularProgress color="primary" size={16} thickness={8} style={{ marginRight: "12px" }} />
              Loading...
            </TableCell>
          </Hidden>
          <Hidden mdUp>
            <TableCell colSpan={3} align="center">
              <CircularProgress color="primary" size={16} thickness={8} style={{ marginRight: "12px" }} />
              Loading...
            </TableCell>
          </Hidden>
        </>
      )}
      {!isFetching && latestBid !== null && (
        <>
          <TableCell padding="checkbox">
            <img alt="logo" src={auctionListing.thumbnailImageUrl} width="95px" height="auto"></img>  
          </TableCell>
          <TableCell>{auctionListing.title}
          {auctionListing?.metadata.isFreeSubscription && (
              <Chip
                size="small"
                color="secondary"
                label="FREE BIDDER LISTING"
                style={{ maxWidth: "185px", display: "flex", marginTop: "0.5rem"  }}
              />
            )}
          </TableCell>
          <Hidden smDown>
            <TableCell>{DateToDateString(new Date(latestBid?.auctionInfo.endDateTimeUtc))}</TableCell>
            <TableCell>{auctionStatus()}</TableCell>
            <TableCell>{currencyFormat(latestBid?.amount ? latestBid?.amount : 0)}</TableCell>
          </Hidden>
          <TableCell>
            <ButtonGroup size="small">
              <Button component={Link} to={`/Lot/${auctionListing.id}`}>
                View
              </Button>
              <Button onClick={() => window.open(`/SalesDocument/${auctionListing.id}`, "_blank")} style={{ width: "150px" }}>
                Sales Document
              </Button>
            </ButtonGroup>
          </TableCell>
        </>
      )}
    </>
  );
};
