import * as React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import * as StripeProductService from "../../../services/StripeProductService";
import * as StripeService from "../../../services/StripeService";
import { useSnackbar } from "notistack";
import { IStripeProduct } from "../../../interfaces/payments/IStripeProduct";
import { currencyFormat } from "../../../helpers/text-format/TextFormat";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ISalvageClientSettings } from "../../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../../services/SettingsService";
import { StripePaymentForm } from "../../payment/StripePaymentForm";
import { IStripePaymentIntentResponse } from "../../../interfaces/payments/IStripePaymentIntentResponse";
import { LoadingComponent } from "../../loading/LoadingComponent";
import { StripePaymentProduct } from "../../payment/StripePaymentProduct";
import { ConfirmFreePaymentForm } from "../../payment/ConfirmFreePaymentForm";

interface IProps {
  paymentStepPostback: () => void;
}

const ClientSettings: ISalvageClientSettings = GetClientSettings();
export const BidderPaymentForm: React.FC<IProps> = ({ paymentStepPostback }) => {
  const { enqueueSnackbar } = useSnackbar();
  const stripePromise = loadStripe(ClientSettings.StripePublicKey);
  const [stripeProducts, setStripeProducts] = React.useState<IStripeProduct[]>();
  const [selectedStripeProduct, setSelectedStripeProduct] = React.useState<IStripeProduct>();
  const [paymentIntent, setPaymentIntent] = React.useState<string>('');
  const [paymentIntentSecret, setPaymentIntentSecret] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [saveCard, setSaveCard] = React.useState<boolean>(false);
  //let saveCard: boolean = false;

  const options = {
    clientSecret: paymentIntentSecret,
  };

  React.useEffect(() => {
    const callbackPaymentIntentId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );

    if (callbackPaymentIntentId != undefined && callbackPaymentIntentId != null) {

      setIsLoading(true);
      StripeService.ProcessPaymentIntentSuccess(callbackPaymentIntentId)
        .then(success => {
          setIsLoading(false);
          let successPaymentIntent = success.parsedBody as IStripePaymentIntentResponse;
          switch (successPaymentIntent.status) {
            case "succeeded":
              enqueueSnackbar("Payment succeeded!", {
                variant: "success",
              });
              paymentStepPostback();
              break;
            case "processing":
              enqueueSnackbar("Your payment is processing.", {
                variant: "info",
              });
              break;
            case "requires_payment_method":
              enqueueSnackbar("Your payment was not successful, please try again.", {
                variant: "error",
              });
              // reload products
              loadStripeProducts();
              break;
            default:
              enqueueSnackbar("Something went wrong.", {
                variant: "error",
              });
              break;
          }
        })
        .catch((error: Response) => {
          enqueueSnackbar("We have been unable to process this payment. Please close your browser and try again", {
            variant: "error",
          });
        })
    } else {
      // they are not on a postback. load the products.
      loadStripeProducts();
    }
  }, []);

  const loadStripeProducts = () => {
    setIsLoading(true);
    StripeProductService.GetActive()
      .then(success => {
        const products = success.parsedBody as IStripeProduct[];
        const freeProduct: IStripeProduct = {
          id: "FREE",
          name: "FREE Subscription",
          description: "Ability to bid only on specific listings available to free users.",
          stripeId: null,
          durationInMonths: null,
          active: true,
          amount: 0,
          vatInclusive: true
        };
        setStripeProducts([freeProduct, ...products]);
        setIsLoading(false);
      })
      .catch((error: Response) => {
        enqueueSnackbar("We have been unable to load subscription products. Please close your browser and try again", {
          variant: "error",
        });
        setIsLoading(false);
      })
  }

  const setSaveCardAndReload = (val: boolean) => {
    setSaveCard(val);

    if (selectedStripeProduct != null) {
      stripeProductChanged(selectedStripeProduct, val);
    }
  }

  const stripeProductChanged = (newValue: IStripeProduct, saveCardVal: boolean | undefined = undefined) => {
    setIsLoading(true);
    setSelectedStripeProduct(newValue);

    if (newValue.stripeId !== null) {
      setTimeout(() => {

        let cardValToUse = saveCardVal != undefined ? saveCardVal : saveCard;

        StripeService.CreatePaymentIntent(newValue?.id, cardValToUse)
          .then(success => {
            let intent = success.parsedBody as IStripePaymentIntentResponse;
            setPaymentIntent(intent.id);
            setPaymentIntentSecret(intent.client_secret);
            setIsLoading(false);
          })
          .catch((error: Response) => {
            enqueueSnackbar("We have been unable to create this payment. Please close your browser and try again", {
              variant: "error",
            });
            setIsLoading(false);
          })

      }, 100);
    } else {
      // Clear stripe payment intent data if FREE subscription plan is selected
      setPaymentIntent('');
      setPaymentIntentSecret('');
      setTimeout(() => setIsLoading(false), 500);
      //paymentStepPostback();
    }
  };

  return (
    <Grid container style={{ marginTop: "20px" }}>
      {stripeProducts != undefined && stripeProducts.length > 0 && !isLoading &&
        <Grid item xs={12}>
          <Typography>
            We (SalvageMarket.co.uk) use the data we collect to create, validate and administer your account, to help us to provide an effective and efficient salvage auction platform and to keep you up to date with any changes. We also use data for training, quality control, research and statistical analysis. If you would like more information on how we use your data and on what your rights are, please have a read of our full privacy policy.
          </Typography>
          <br></br>
          <Grid container>
            {stripeProducts?.map((stripeProduct, index) => (
              <StripePaymentProduct key={`stripe-product${index}`} stripeProduct={stripeProduct} selectedStripeProduct={selectedStripeProduct} isSignUpMode={true} bidder={null} stripeProductChanged={stripeProductChanged} />
            ))}
          </Grid>

          {/* <Grid container>
            <Grid item xs={12}>
              <a
                style={{ fontSize: "16px", margin: "30px 0 15px 0", cursor: "pointer", display: "block", position: "relative", textDecoration: "underline", textAlign: "center" }}
                onClick={() => { setSelectedStripeProduct(undefined); paymentStepPostback(); }}>Choose a plan later</a>
            </Grid>
          </Grid> */}

          {/* {selectedStripeProduct?.id === 'FREE' && (
            <Grid container>
              <Grid item xs={12}>
                <a
                  style={{ fontSize: "16px", margin: "30px 0 15px 0", cursor: "pointer", display: "block", position: "relative", textDecoration: "underline", textAlign: "center" }}
                  onClick={() => { paymentStepPostback(); }}>Confirm Free Subscription</a>
              </Grid>
            </Grid>
          )} */}

          {selectedStripeProduct?.id === 'FREE' &&
            <ConfirmFreePaymentForm onLoadingChange={(loading) => { }} confirmPostBack={paymentStepPostback} />
          }

          <Grid container>
            <Grid item xs={12}>

              {paymentIntentSecret != undefined && paymentIntentSecret != '' &&
                <Elements options={options} stripe={stripePromise} >
                  <Typography style={{ fontWeight: "bold", textAlign: "center", marginTop: "40px" }} component="h3" variant="h6">
                    Make your payment
                  </Typography>
                  <Typography style={{ maxWidth: "890px", margin: "0 auto", textAlign: "center", }}  >We (SalvageMarket.co.uk) use the data we collect to create, validate and administer your account, to help us to provide an effective and efficient salvage auction platform.</Typography>
                  <br></br>
                  <StripePaymentForm paymentIntent={paymentIntent} paymentIntentSecret={paymentIntentSecret} paymentMethod={'new'} redirectUrl={'/Register'} saveCard={saveCard} updateSaveCard={(e) => { setSaveCardAndReload(e); }} refresh={loadStripeProducts} />
                </Elements>
              }
            </Grid>
          </Grid>
        </Grid>
      }
      {isLoading &&
        <Grid item xs={12}>
          <LoadingComponent label="Please Wait"></LoadingComponent>
        </Grid>
      }
    </Grid>
  );
};
